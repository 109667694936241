.tooltip-container {
  position: relative;
  cursor: default;
}
.tooltip {
  position: absolute;
  left: -24px;
  background-color: #666666;
  box-shadow: 0 0 16px -4px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  font-size: 11px;
  color: #fff;
  font-family: AvenirRoman;
  white-space: nowrap;
  display: inline-block;
  padding: 4px 8px;
  bottom: 100%;
  margin-bottom: 8px;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms linear;
}
.tooltip b {
  font-family: AvenirHeavy;
}
.tooltip-small {
  left: 10px;
}
.tooltip-left {
  // fields on the far left of the screen need a left margin
  @extend .tooltip;
  left: 10px;
}
.tooltip-right {
  // fields on the far right of the screen need a right margin
  @extend .tooltip;
  left: unset;
  right: -24px;
}
.tooltip-medium-width {
  @extend .tooltip;
  white-space: pre-wrap;
  width: 240px;
}
.tooltip-small-width {
  @extend .tooltip;
  min-width: 100px;
  word-break: break-word;
}
.tooltip-container:hover .tooltip {
  opacity: 1;
  visibility: visible;
}
@media only screen and (max-width: 560px) {
  .tooltip {
    left: auto;
    right: 0;
    white-space: pre-wrap;
  }
}
@media only screen and (max-width: 400px) {
  .tooltip {
    right: -8px;
    font-size: 10px;
  }
}
